
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CSpinner from "@/components/spinner.vue";
import { ProcedureCartForm } from "@/store/cart/types";

const VExamAdd = defineComponent({
  name: "VExamAdd",
  components: { CSpinner },
  setup() {
    useHead({ title: "Agendar exame | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const loading = reactive({ submit: false });

    const form = reactive<ProcedureCartForm>({
      cd_procedimento: [Number(route.query.pe)],
      cd_estabelecimento: Number(route.query.et?.toString()) || null,
      cd_pessoa_fisica: Number(route.query.pf?.toString()) || null,
    });

    function showErrorMessage() {
      store.commit("addToast", {
        summary: "OPS... Tivemos problemas ao agendar, tente novamente mais tarde!",
        severity: "error",
      });
      router.back();
    }

    function validate() {
      return form.cd_estabelecimento && form.cd_pessoa_fisica && form.cd_procedimento && Number(route.query.hr);
    }

    async function addExamToCart() {
      loading.submit = true;
      const response = await store.dispatch("addProcedureToCart", {
        _id: Number(route.query.hr),
        form,
      });
      loading.submit = false;

      if (!response) return router.back();
      if (response.status !== 200) return showErrorMessage();

      store.commit("setToast", [{ summary: "Agendamento adicionado ao carrinho!", severity: "success" }]);
      router.replace({ name: "cart" });
    }

    if (validate()) addExamToCart();
    else {
      showErrorMessage();
      router.replace({ name: "exam", query: route.query });
    }

    return { loading };
  },
});

export default VExamAdd;
